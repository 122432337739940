<script setup>
import 'moment/locale/it'
import { sbapibackoffice } from '@/api'
import { spinnerStore } from '@/stores/spinner'
import { navbarStore } from '@/stores/navbar'
import { ref, reactive } from '@vue/reactivity'
import { inject, onMounted } from '@vue/runtime-core'
import dayjs from 'dayjs'
import Multiselect from '@vueform/multiselect'
import { permissionsStore } from '@/stores/permissions'
import VPagination from '@hennge/vue3-pagination'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'

let logs = reactive([])
const filters = reactive({
  filterType: [],
  order: {
    field: 'timestampAction',
    asc: false
  },
  search: '',
  dateFrom: null,
  dateTo: null
})
const permissions = permissionsStore()
const logsType = reactive([])
const headers = [
  { title: 'Date', field: 'timestampAction' }, 
  { title: 'Log', field: 'action' },
  { title: 'Descrizione', field: '' },
  { title: 'Utente', field: '' }]
const spinner = spinnerStore()
const navbar = navbarStore()
const toast = inject('$toast')
const showCoupon = ref('')
const view = ref('PRIVATE')

/**
     * Set the order condition for the table
     * @param {number} orderBy - the column to order by
     */
const changeOrder = (orderBy) => {
  if (filters.order.field == orderBy) {
    filters.order.asc = !filters.order.asc
  } else {
    filters.order.field = orderBy
    filters.order.asc = true
  }

  getLogs()
}

// used to manage the search input debounce time
const typingTimer = ref(null)

/**
     * manage the search input
     * @param {Event} event - the oninput input event
     */
const filterSearch = ({ target }) => {
  filters.search = target.value

  // execute getReferrals after 500ms of non typing
  clearTimeout(typingTimer.value)
  typingTimer.value = setTimeout(() => {
    page.value = 1
    getLogs()
  }, 1000)
}

const dateFromChanged = ({ target }) => {
  filters.dateFrom = target.value ? new Date(target.value) : undefined
  page.value = 1
  getLogs()
}

const dateToChanged = ({ target }) => {
  filters.dateTo = target.value ? new Date(target.value) : undefined
  page.value = 1
  getLogs()
}

const getLogs = () => {
  spinner.show()
  sbapibackoffice.get('/user/actions', {
    withCredentials: true,
    params: {
      page: page.value - 1,
      size: 10,
      sort: `${filters.order.field},${filters.order.asc ? 'asc' : 'desc'}`,
      search: filters.search || undefined,
      startDateFilter: filters.dateFrom ? dayjs(filters.dateFrom).format('YYYY-MM-DD') : undefined,
      endDateFilter: filters.dateTo ? dayjs(filters.dateTo).format('YYYY-MM-DD') : undefined,
      listType: view.value === 'ALL' ? 'ALL' : undefined,
      actions: filters.filterType.length > 0 ? filters.filterType.join() : undefined
    }
  })
    .then(({ data }) => {

      pages.value = data.totalPages
      totalElements.value = data.totalElements
      numberOfElements.value = data.numberOfElements

      data.content.forEach(d => {
        if (d.description) {
          if (d.description.indexOf('N° codici sconto') != -1) {
            d.description = d.description.replace('Aggiornata campagna', 'Aggiornata campagna.')
            d.description = d.description.split('.')
            d.descriptionSconti = d.description[1]
            d.descriptionSconti = d.descriptionSconti.split(':')
            d.descriptionSconti[1] = d.descriptionSconti[1].split(',')
            delete d.description[1]
          } else {
            if (d.description.indexOf('Aggiornata azienda') != -1) {
              d.description = d.description.replace('Aggiornata azienda', 'Aggiornata azienda.')
            }
            if (d.description.indexOf('Aggiornata campagna') != -1) {
              d.description = d.description.replace('Aggiornata campagna', 'Aggiornata campagna.')
            }
            d.description = d.description.split('.')
          }

        }
      })

      logs = []

      logs.push(...data.content)
      logs.forEach(log => {
        if (logsType.indexOf(log.action) === -1) {
          logsType.push(log.action)
        }
      })
    })
    .catch((error) => {
      toast.error('Qualcosa è andato storto')
      console.error(error)
    })
    .finally(() => {
      spinner.hide()
    })
}

const getFilteredRows = () => {
  let rows = logs
  return rows
}

const page = ref(1)
const pages = ref(0)
const totalElements = ref(0)
const numberOfElements = ref(0)

onMounted(async () => {
  navbar.title = 'Storico'

  getLogs()
})

const showCouponList = (id) => {
  if (showCoupon.value === id) {
    showCoupon.value = ''
  } else {
    showCoupon.value = id
  }
}

const filterTypeChanged = (value) => {
  filters.filterType = value

  setTimeout(() => {
    page.value = 1
    getLogs()
  }, 100)
}

const show = (type) => {
  view.value = type

  getLogs()
}
</script>

<template>
  <div class="referrals" :style="pages > 1 ? 'padding-bottom: 0;' : ''">
    <div v-if="permissions.canUse('ADMIN')" style="display: flex; gap: 1em;">
      <material-button text="Personali" type="info" style="width: 150px;" :class="{ disabled: view != 'PRIVATE' }" @click="show('PRIVATE')" />
      <material-button text="Tutti" type="info" style="width: 150px;" :class="{ disabled: view != 'ALL' }" @click="show('ALL')" />
    </div>
    <div class="filters">
      <div class="filters-container">
        <Multiselect
          v-model="filters.filterType"
          :placeholder="'Filtra per log'"
          class="search-select"
          :options="logsType"
          mode="multiple"
          :can-deselect="true"
          :can-clear="true"
          :hide-selected="false"
          @change="filterTypeChanged"
        >
          <template #multiplelabel="{ values }">
            <div class="multiselect-multiple-label">
              <span v-if="values.length === 1" class="label">{{ values[0].label }}</span>
              <span v-if="values.length > 1">
                <span class="label">{{ values[0].label }}</span>
                <span v-if="values.length - 1 === 1" class="label">+ {{ values.length - 1 }} filtro selezionato</span>
                <span v-else class="label">+ {{ values.length - 1 }} filtri selezionati</span>
              </span>
            </div>
          </template>
          <template #option="{ option }">
            <span>{{ option.value }} </span>
            <span v-if="filters.filterType.find(opt => opt == option.value)" class="close">
              deselect
              <svg v-show="false" style="width:18px;height:18px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
              </svg>
            </span>
          </template>
        </Multiselect>
        <div class="search">
          <label for="search">Cerca</label>
          <input id="search" type="text" name="" placeholder="Nome, Utente CVing" @input="filterSearch($event)">
        </div>
      </div>
      <div class="filters-container">
        <div class="date-box">
          <label for="date-from">Data inizio</label>
          <input id="date-from" type="date" @input="dateFromChanged($event)">
        </div>

        <div class="date-box">
          <label for="date-to">Data fine</label>
          <input id="date-to" type="date" @input="dateToChanged($event)">
        </div>

        <div class="lens">
          <font-awesome-icon icon="magnifying-glass" class="fa-lg" />
        </div>
      </div>
    </div>
    <div class="table">
      <table cellspacing="0">
        <thead>
          <tr>
            <th v-for="header of headers" :key="header" :class="{ orderable : header.field }" @click="header.field && changeOrder(header.field)">
              <div class="">
                <span>{{ header.title }}</span>
                <font-awesome-icon v-if="filters.order.field == header.field" :icon="filters.order.asc ? 'arrow-down-a-z' : 'arrow-down-z-a'" />
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr v-for="log in getFilteredRows()" :key="log.id">
            <td><span>{{ dayjs(log.timestampAction).format('DD/MM/YYYY') }}</span></td>
            <td>
              <div style="font-size:13px">
                {{ log.action }}
              </div>
            </td>
            <td>
              <div v-for="(d, index) in log.description" :key="d + '__' + index">
                {{ d }}
              </div>
              <div v-if="log.descriptionSconti && log.descriptionSconti[1]">
                <material-button style="margin:8px 0;" :small="true" :text="showCoupon === log.id ? 'Nascondi codici' : 'Mostra codici'" type="info" @click="showCouponList(log.id)" />

                <div v-if="showCoupon === log.id" class="chips-container">
                  <div v-for="(d, index) in log.descriptionSconti[1]" :key="d + '__' + index" style="margin:8px 0;">
                    <div class="chips">
                      {{ d }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <span>{{ log.emailUserBackoffice }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="pages > 1" class="pagination">
      <div>Visualizzati {{ numberOfElements }} di {{ totalElements }}</div>
      <v-pagination v-model="page" :pages="pages" active-color="#bcde42" @update:modelValue="getLogs" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '../assets/scss/referrals';
@use '../assets/scss/table';

.chips {
  background: var(--info);
  color: white;
  border-radius: 5px;
  padding: 2px 5px;
  font-size: 12px;
}

.referrals .filters {
  flex-direction: column;

  .filters-container {
    width: 100%;
    display: flex;
    gap: 10px;

    &:first-of-type {
      padding-right: 30px;
    }
    
    div.search,
    div.date-box {
      width: 100%;
    }
  }
}

.referrals table thead,
.referrals table thead tr:first-child,
.referrals table thead tr:first-child th {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.referrals table tr th:first-child,
.referrals table .table-body tr td:first-child {
  padding-left: 1.5rem !important;
}

.text-xs {
  font-size: 13px;
  margin-top: 4px;
}

table {
  th {
    &:nth-child(1) {
      width: 100px;
    }
  }
}
</style>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
input.multiselect-search,
.multiselect-search {
  background: transparent !important;
  background-color: transparent !important;
}

.multiselect {
  min-width: calc(50% - 5px);
  background: transparent;
  color: white;
  height: 41.5px !important;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: none !important;
  outline: none !important;

  input {
    background: transparent !important;
  }

  &.is-active {
    box-shadow: none !important;
    outline: none !important;
  }
}

.multiselect-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.multiselect-dropdown {
  background: #004278;
  color: white;
  z-index: 999999;
  box-shadow: none !important;
  outline: none !important;

  input {

    &:focus,
    &:focus-visible,
    &:hover {
      box-shadow: none !important;
      outline: none !important;
    }
  }
}

.multiselect-multiple-label span.label {
  font-size: 12px;
  color: white;
  background: var(--info);
  padding: 5px 10px;

  border-radius: 3px;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }
}

ul.multiselect-options li.multiselect-option {
  font-size: 11px;
}

.multiselect-option.is-pointed {
  background: rgba(16, 252, 224, 0.25) !important;
  color: white;
  position: relative;
}

.multiselect-caret {
  background: white;
  margin-right: 10px;
}

.multiselect-search {
  background: transparent !important;
}

.multiselect-option.is-selected {
  background: #4c75997b !important;
  position: relative;
}

li.is-selected span.close {
  position: absolute !important;
  right: 5px;
  color: #b8cee0;
}

.multiselect-clear-icon {
  background: #b8cee0;
}

.multiselect .multiselect-clear:hover .multiselect-clear-icon {
  background: white;
}

.chips-container {
  display: block;

  //max-width: fit-content;
  //width: fit-content;
  div {
    display: block;
    max-width: fit-content;
    width: fit-content;
  }
}

.pagination {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ul.Pagination {
    justify-content: flex-end;

    li.PaginationControl>path {
      fill: rgba(255, 255, 255, 0.233);
    }

    .Control-active {
      fill: white;
    }

    .Page {
      width: 25px;
      height: 25px;
      color: white;
    }

    button.Page-active {
      background: var(--accented) !important;
      border: 1px solid var(--accented) !important;
      color: #003e73;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block;

  &.dot-color0 {
    background-color: #878787;
  }

  &.dot-color1 {
    background-color: #09ff00;
  }

  &.dot-color2 {
    background-color: #ff4500;
  }
}
</style>